'use strict'
import '../../styles/blocks/_client-stories.scss'
import Swiper, {EffectCreative, Navigation} from 'swiper';
import 'swiper/scss'
import 'swiper/scss/effect-creative'

Swiper.use([Navigation, EffectCreative]);

const initSlider = () => {
    const slider = new Swiper('.js-slider-stories .swiper', {
        effect: "creative",
        loop: true,
        speed: 550,
        slidesPerView: 1,
        centerMode: true,
        centeredSlides: true,
        spaceBetween: 50,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        creativeEffect: {
            limitProgress: 2,
            prev: {
                opacity: 0,
                translate: ["-65%", 0, -200],
                scale: 0.55,
            },
            next: {
                opacity: 0,
                translate: ["+65%", 0, -200],
                scale: 0.55,
            }
        }
    });

}

$(window).on('load', function () {
    initSlider()
});
